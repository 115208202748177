@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px !important;
}

@layer base {
  :root {
    --background: 240 4.8% 95.9%;
    --background-special: linear-gradient(90deg, #f4f4f5 0%, #ebdbdb 100%);
    --foreground: 240 3.7% 15.9%;
    --card: 240 4.8% 95.9%;
    --card-foreground: 240 3.7% 15.9%;
    --card-secondary: 220, 13%, 91%;
    --popover: 240 4.8% 95.9%;
    --popover-foreground: 240 3.7% 15.9%;
    --primary: 12 100% 53%;
    --primary-dark: 10 75.6% 41.8%;
    --primary-foreground: 240 4.8% 95.9%;
    --secondary: 240 4.9% 83.9%;
    --secondary-foreground: 240 3.7% 15.9%;
    --muted: 240 4.9% 83.9%;
    --muted-foreground: 240 3.8% 33.9%;
    --accent: 240 4.9% 83.9%;
    --accent-foreground: 240 3.7% 15.9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 240 3.7% 15.9%;
    --warning: 51 100% 50%;
    --warning-foreground: 240 4.8% 95.9%;
    --success: 240 4.8% 95.9%;
    --success-foreground: 120 60.8% 50%;
    --border: 240 4.9% 83.9%;
    --input: 240 4.9% 83.9%;
    --ring: 24.6 95% 53.1%;
    --radius: 0.5rem;
    --media-control-padding: 0px 0px;
    --media-control-background: transparent;
    --media-range-bar-color: rgba(255, 63, 15);
    --media-time-range-buffered-color: rgb(80, 80, 80);
    --media-range-track-background: rgb(43, 43, 43);
    --media-control-hover-background: transparent;
    --media-button-icon-width: 20px;
    --media-button-icon-height: 20px;
  }

  .dark,
  :root[class~="dark"] {
    --background: 240 3.7% 15.9%;
    --background-special: linear-gradient(90deg, #27272a 0%, #3b2929 100%);
    --foreground: 240 5.9% 90%;
    --card: 240 3.7% 15.9%;
    --card-foreground: 240 5.9% 90%;
    --card-secondary: 240, 6%, 20.3%;
    --popover: 240 3.7% 15.9%;
    --popover-foreground: 240 5.9% 90%;
    --primary: 12 100% 53%;
    --primary-dark: 10 75.6% 41.8%;
    --primary-foreground: 240 5.9% 90%;
    --secondary: 240 5.3% 26.1%;
    --secondary-foreground: 240 5.9% 90%;
    --muted: 240 5.3% 26.1%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 5.3% 26.1%;
    --accent-foreground: 240 5.9% 90%;
    --destructive: 0 72.2% 50.6%;
    --destructive-foreground: 240 5.9% 90%;
    --warning: 45 93.4% 47.5%;
    --warning-foreground: 240 3.7% 15.9%;
    --success: 240 3.7% 15.9%;
    --success-foreground: 90 100% 49%;
    --border: 240 5.3% 26.1%;
    --input: 240 5.3% 26.1%;
    --ring: 20.5 90.2% 48.2%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/**
 * Applies a special background gradient to an element.
 * This style is typically used for elements that require a more visually
 * interesting or dynamic background, such as hero sections or other
 * prominent areas of the UI.
 */
.bg-background-special {
  background: var(--background-special);
}

/* cyrillic-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZJhiJ-Ek-_EeAmM.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZthiJ-Ek-_EeAmM.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZNhiJ-Ek-_EeAmM.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZxhiJ-Ek-_EeAmM.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZBhiJ-Ek-_EeAmM.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZFhiJ-Ek-_EeAmM.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hiJ-Ek-_EeA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.masquerading #nav {
  background-color: hsl(12 100% 53%) !important;
}

video::cue {
  background-color: hsl(240 4.8% 95.9%);
  color: hsl(240 3.7% 15.9%);
  position: relative;
  top: -10px;
}
